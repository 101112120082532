import request from '@/utils/request2'


//新获取短信验证码
export function fetchUserLoginSmsCode(params) {
    return request({
        url: '/api/login/kfsendcode',
        method: 'post',
		data:params
    })
}

//登录获取账号密码
export function fetchUserLoginToken(params) {
    return request({
        url: '/api/login/loginbycode',
        method: 'post',
        data: params
    })
}

//登录账号密码
export function fetchUserLoginToken2(params) {
    return request({
        url: '/api/login/loginbycode',
        method: 'post',
        data: params
    })
}


export function getuinfo(params) {
    return request({
        url: '/api/login/getuinfo',
        method: 'post',
        data: params
    })
}

export function kf_send_msg(params) {
    return request({
        url: '/api/user_im/kf_send_msg',
        method: 'post',
        data: params
    })
}
